import React, {useEffect, useMemo, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {useRecoilState} from "recoil";
import {user as userAtom} from "../../../atoms/user";
import {useNavigate, useParams} from "react-router-dom";
import DataService from "../../../services/DataService";
import Table from "react-bootstrap/Table";
import Team from "../../../component/Team";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCodePullRequest, faPencil} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Accordion from 'react-bootstrap/Accordion';

export default function BerichtListe() {
    const service = useMemo(() => DataService.getInstance(), []);
    const [loadingBerichte, setLoadingBerichte] = useState<boolean>(false);
    const [loadingPlayerUpdate, setLoadingPlayerUpdate] = useState<boolean>(false);
    const [berichte, setBerichte] = useState<SpielBerichtDataListElement[]>([]);
    const [playerUpdates, setPlayerUpdates] = useState<PlayerUpdate[]>([]);
    const [user, setUser] = useRecoilState(userAtom);

    // @ts-ignore
    const params: { saison: number } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(user.token === "") {
            navigate("/saison/" + params.saison + "/login");
        } else {
            setLoadingBerichte(true);
            setLoadingPlayerUpdate(true);
            service.getSpielberichtListe(params.saison, user.token, setUser, setBerichte, setLoadingBerichte);
            service.admin().getPlayerUpdates(params.saison, user.token, setUser, setPlayerUpdates, setLoadingPlayerUpdate);
        }
    }, [user.token, service, params.saison, navigate, setUser]);

    return (
        <Container>
            <Row>
                <Col>
                    <h3>Spielberichte</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table striped>
                        <thead>
                        <tr>
                            <th>Spieltag</th>
                            <th>Heim</th>
                            <th>Gast</th>
                            <th>&nbsp;</th>
                            <th>Data</th>
                            <th>Edit</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            loadingBerichte ?
                                <tr>
                                    <td colSpan={6}>
                                        <div className="spinner-grow" role="status"/>
                                    </td>
                                </tr>
                                :
                                berichte.map(data =>
                                    <tr key={data.id}>
                                        <td>{data.spieltag}</td>
                                        <td><Team team={{id: data.heim, name: ''}} onlyLogo={true} /></td>
                                        <td><Team team={{id: data.gast, name: ''}} onlyLogo={true} /></td>
                                        <td>
                                            <Button variant="primary" size="sm" onClick={(e) => {
                                                setLoadingBerichte(true);
                                                service.processSpielbericht(params.saison, data.id, user.token, setUser, setBerichte, setLoadingBerichte);
                                            }}>
                                                <FontAwesomeIcon icon={faCodePullRequest} /> Verarbeiten
                                            </Button>
                                        </td>
                                        <td>
                                            <Accordion>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Json Daten</Accordion.Header>
                                                    <Accordion.Body>
                                                <pre className={"textSmall"}>
                                                    {JSON.stringify(data.data, null, 2)}
                                                </pre>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </td>
                                        <td>
                                            <Button variant="primary" size="sm" onClick={(e) => {
                                                navigate("/saison/" + params.saison + "/bericht?edit=true&id="+data.id);
                                            }}>
                                                <FontAwesomeIcon icon={faPencil} />
                                            </Button>
                                        </td>
                                    </tr>
                                )
                        }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Spieler Updates</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table striped>
                        <thead>
                        <tr>
                            <th>Spieltag</th>
                            <th>User</th>
                            <th>Data</th>
                            <th>&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            loadingPlayerUpdate ?
                                <tr>
                                    <td colSpan={4}>
                                        <div className="spinner-grow" role="status"/>
                                    </td>
                                </tr>
                                :
                                playerUpdates.map(update =>
                                    <tr>
                                        <td>{update.tag}</td>
                                        <td>{update.user}</td>
                                        <td>
                                            <Accordion>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Json Daten</Accordion.Header>
                                                    <Accordion.Body>
                                                <pre className={"textSmall"}>
                                                    {JSON.stringify(JSON.parse(update.data), null, 2)}
                                                </pre>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </td>
                                        <td>
                                            <Button variant="primary" size="sm" onClick={(e) => {
                                                setLoadingPlayerUpdate(true);
                                                service.team().updatePlayer(params.saison, user.token, setUser, JSON.parse(update.data), d => {
                                                    service.admin().getPlayerUpdates(params.saison, user.token, setUser, setPlayerUpdates, setLoadingPlayerUpdate);
                                                }, b=> b);
                                            }}>
                                                <FontAwesomeIcon icon={faCodePullRequest} /> Verarbeiten
                                            </Button>
                                        </td>
                                    </tr>
                                )
                        }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}