import BaseService from "./BaseService";

export default class DashboardService extends BaseService {

    public getTeamtData(saison: number, token: string, setUser: (u:User) => void, setData: (d: TeamOverview) => void, setLoading: (p:boolean) => void): void {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison)+"member/dashboard/team", {
            headers: {
                'Accept': 'application/json',
                'Authorization': authToken
            }
        })
        .then(r => {
            if(r.ok) {
                return r.json() as Promise<ApiResponse<TeamOverview>>;
            } else {
                return Promise.reject(r);
            }
        })
        .then(r => setData(r.data))
        .catch(e => setUser({token: "", name: "", rolle: ""}))
        .finally(() => setLoading(false));
    }

    public getGegner(saison: number, token: string, setUser: (u:User) => void, setData: (d: TeamInfo) => void, setLoading: (p:boolean) => void): void {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison)+"member/dashboard/gegner", {
            headers: {
                'Accept': 'application/json',
                'Authorization': authToken
            }
        })
            .then(r => {
                if(r.ok) {
                    return r.json() as Promise<ApiResponse<TeamInfo>>;
                } else {
                    return Promise.reject(r);
                }
            })
            .then(r => setData(r.data))
            .catch(e => setUser({token: "", name: "", rolle: ""}))
            .finally(() => setLoading(false));
    }

    public getWetten(saison: number, token: string, setUser: (u:User) => void, setData: (d: WettOverview) => void, setLoading: (p:boolean) => void): void {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison)+"member/dashboard/wetten", {
            headers: {
                'Accept': 'application/json',
                'Authorization': authToken
            }
        })
            .then(r => {
                if(r.ok) {
                    return r.json() as Promise<ApiResponse<WettOverview>>;
                } else {
                    return Promise.reject(r);
                }
            })
            .then(r => setData(r.data))
            .catch(e => setUser({token: "", name: "", rolle: ""}))
            .finally(() => setLoading(false));
    }

    public getEvents(saison: number, token: string, setUser: (u:User) => void, setData: (p:AppEvent[]) => void, setLoading: (p:boolean) => void) {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison)+"admin/events?size=3", {
            headers: {
                'Accept': 'application/json',
                'Authorization': authToken
            }
        })
            .then(r => {
                if(r.ok) {
                    return r.json() as Promise<ApiResponse<AppEvent[]>>;
                } else {
                    return Promise.reject(r);
                }
            })
            .then(r => setData(r.data))
            .catch(e => setUser({token: "", name: "", rolle: ""}))
            .finally(() => setLoading(false));
    }

    public getEnddate(saison: number, token: string, setUser: (u:User) => void, setData: (p:Enddates) => void, setLoading: (p:boolean) => void) {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison)+"member/dashboard/enddate", {
            headers: {
                'Accept': 'application/json',
                'Authorization': authToken
            }
        })
        .then(r => {
            if(r.ok) {
                return r.json() as Promise<ApiResponse<Enddates>>;
            } else {
                return Promise.reject(r);
            }
        })
        .then(r => setData(r.data))
        .catch(e => setUser({token: "", name: "", rolle: ""}))
        .finally(() => setLoading(false));
    }
}