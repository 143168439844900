import React, {useEffect, useMemo, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import DataService from "../../services/DataService";
import {useParams} from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Team from "../../component/Team";
import TeamInfo from "../../component/TeamInfo";
import {eventComparator} from "../../util/Utils"
import {emptySpielBerichtData} from "../../util/EmptyObjects";

export default function Spielbericht() {
    const service = useMemo(() => DataService.getInstance(), []);
    const [data, setData] = useState<SpielBerichtData>(emptySpielBerichtData);
    const [loading, setLoading] = useState<boolean>(false);
    // @ts-ignore
    const params: { saison: number, tag: number, heim: number, gast: number } = useParams();

    useEffect(() => {
        setLoading(true);
        service.getSpielbericht(params.saison, params.tag, params.heim, params.gast, setData, setLoading);
    }, [service, params.saison, params.tag, params.heim, params.gast]);

    return (
        <Container>
            {
                loading ?
                    <div className="spinner-grow" role="status" />
                :
                    <Container className={"lightGreyBg"}>
                        <Row>
                            <Col><h1>Spielbericht</h1></Col>
                        </Row>
                        <Row className={"bold"}>
                            <Col>{data.heim.name}</Col>
                            <Col>vs.</Col>
                            <Col>{data.gast.name}</Col>
                        </Row>
                        <Row>
                            <Col><span className={"bold"}>Punkte:</span> {data.heim.punkte}</Col>
                            <Col>&nbsp;</Col>
                            <Col><span className={"bold"}>Punkte:</span> {data.gast.punkte}</Col>
                        </Row>
                        <Row>
                            <Col>&nbsp;</Col>
                        </Row>
                        <Row>
                            <Col><span className={"bold"}>Wetter:</span> {data.wetter}</Col>
                            <Col><span className={"bold"}>Anstoßendes Team:</span> {data.kickoff}</Col>
                            <Col><span className={"bold"}>Aussenseiter:</span> {data.aussenseiter}</Col>
                        </Row>
                        <Row>
                            <Col>&nbsp;</Col>
                        </Row>
                        <Row>
                            <Col>
                                <TeamInfo data={data.heim} edit={false} />
                            </Col>
                            <Col>
                                <TeamInfo data={data.gast} edit={false} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>&nbsp;</Col>
                        </Row>
                        <Row>
                            <Col>
                                <h3>Aktion wären des Spiel</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Container className={"greyBg"}>
                                    <Table striped>
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Halbzeit</th>
                                            <th>Zug</th>
                                            <th>Akteur</th>
                                            <th>Aktion</th>
                                            <th>Ziel</th>
                                            <th>Reaktion</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            data.events.sort(eventComparator).map((event, index) =>
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{event.halbzeit}</td>
                                                    <td>{event.zug}</td>
                                                    <td><Team team={{id: event.akteurTeam, name: ''}} small={true}
                                                              onlyLogo={true}/>{event.akteur}</td>
                                                    <td>{event.aktion}</td>
                                                    <td><Team team={{id: event.zielTeam, name: ''}} small={true}
                                                              onlyLogo={true}/>{event.ziel}</td>
                                                    <td>{event.reaktion}</td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                </Container>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Container>
                                    <Table striped className={"caption-top"}>
                                        <caption>Gekaufte Verbesserungen</caption>
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Verbesserung</th>
                                            <th>Wertsteigerung</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            data.playerSspList.filter(p => p.verbesserung !== '').map((ssp, index) =>
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {ssp.teamId && <Team team={{id: ssp.teamId, name: ''}} small={true} onlyLogo={true}/> }
                                                        {ssp.name}
                                                    </td>
                                                    <td>{ssp.verbesserung}</td>
                                                    <td>{ssp.wertsteigerung}</td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                </Container>
                            </Col>
                            <Col>
                                <Container>
                                    <Table striped className={"caption-top"}>
                                        <caption>Neu Angeworbene Spieler</caption>
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Typ</th>
                                            <th>Kosten</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            data.hired.map((player, index) =>
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td><Team team={{id: player.teamId, name: ''}} small={true}
                                                              onlyLogo={true}/>{player.name}</td>
                                                    <td>{player.typ}</td>
                                                    <td>{player.kosten}</td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
            }
        </Container>
    );
}