
export const touchdown: ActionSSP = {id:9, ssp:3};
export const pass: ActionSSP = {id:1, ssp:1};
export const thorwTeammeat: ActionSSP = {id:3, ssp:1};
export const interceptPass: ActionSSP = {id:6, ssp:1} ;
export const successfulInterceptPass: ActionSSP = {id:7, ssp:2};
export const woundenPlayer: ActionSSP[] = [{id:7, ssp:2},{id:8, ssp:2},{id:9, ssp:2},{id:10, ssp:2},{id:11, ssp:2},{id:12, ssp:2},{id:13, ssp:2},{id:14, ssp:2},{id:15, ssp:2},{id:18, ssp:2}];
export const playerOfTheGame: ActionSSP = {id:11, ssp:4};
export const BLOCK_ACTION: number = 4;
export const FOUL_ACTION: number = 5;
export const THROW_SUCCESSFUL: number = 17;

export function evaluateEvents(events: GameEvent[], heimId: number, setTouchdownsHeim: (td:number) => void, gastId: number, setTouchdownsGast: (td:number) => void): SspPlayer[]  {
    let heimTouchdowns = 0;
    let gastTouchdowns = 0;
    const sspPlayer: SspPlayer[] = [];
    events.forEach(event => {
        if (event.aktion.id === touchdown.id) {
            if(event.akteur.teamId === heimId) {
                heimTouchdowns = evaluateTouchdown(event, heimTouchdowns, sspPlayer, touchdown.ssp);
            } else if(event.akteur.teamId === gastId) {
                gastTouchdowns = evaluateTouchdown(event, gastTouchdowns, sspPlayer, touchdown.ssp);
            }
        } else if (event.aktion.id === interceptPass.id) {
            evaluateSSpPoints(event.akteur, sspPlayer, interceptPass.ssp);
        } else if(event.aktion.id === successfulInterceptPass.id) {
            evaluateSSpPoints(event.akteur, sspPlayer, successfulInterceptPass.ssp);
        } else if(event.aktion.id === playerOfTheGame.id) {
            evaluateSSpPoints(event.akteur, sspPlayer, playerOfTheGame.ssp);
        } else if(event.aktion.id === pass.id && event.reAktion?.id === THROW_SUCCESSFUL) {
            evaluateSSpPoints(event.akteur, sspPlayer, pass.ssp);
        } else if(event.aktion.id === thorwTeammeat.id && event.reAktion?.id === THROW_SUCCESSFUL) {
            evaluateSSpPoints(event.akteur, sspPlayer, thorwTeammeat.ssp);
        } else if((event.aktion.id === BLOCK_ACTION || event.aktion.id === FOUL_ACTION) && event.reAktion != null) {
            const sspObj = woundenPlayer.find(a => a.id === event.reAktion?.id);
            if(sspObj !== undefined) {
                evaluateSSpPoints(event.akteur, sspPlayer, sspObj.ssp);
            }
        }
    });
    setTouchdownsHeim(heimTouchdowns);
    setTouchdownsGast(gastTouchdowns);
    return sspPlayer;
}

///////////////////////////////////////////

function evaluateTouchdown(event: GameEvent, td: number, sspPlayer: SspPlayer[], ssp: number) {
    td++;
    evaluateSSpPoints(event.akteur, sspPlayer, ssp);
    return td;
}

function evaluateSSpPoints(akteur: BasePlayer, sspPlayer: SspPlayer[], ssp: number) {
    const index = sspPlayer.findIndex(s => s.id === akteur.id);
    if (index === -1) {
        sspPlayer.push({id: akteur.id, teamId: akteur.teamId, name: akteur.name, ssp: ssp});
    } else {
        sspPlayer[index].ssp += ssp;
    }
}

//////////////////////////////////

export class WetterArt {
    private readonly _id: number;
    private readonly _name: string;

    constructor(id: number, name: string) {
        this._id = id;
        this._name = name;
    }

    public get id(): number {
        return this._id;
    }

    public get name(): string {
        return this._name;
    }
}

export const SPRING = new WetterArt(1, "Frühling");
export const SUMMER = new WetterArt(2, "Sommer");
export const FALL = new WetterArt(3, "Herbst");
export const WINTER = new WetterArt(4, "Winter");
export const STANDARD = new WetterArt(5, "Standard");

export const WETTER_LIST = [SPRING,SUMMER,FALL,WINTER,STANDARD];

export const WetterByArt = (art:number):WetterArt => WETTER_LIST[art-1];

//////////////////////////////////

export function eventComparator(a: BaseEvent, b: BaseEvent): number {
    if(a.halbzeit > b.halbzeit) {
        return 1;
    } else if(a.halbzeit < b.halbzeit) {
        return -1;
    }
    return a.zug - b.zug;
}