import React, {useEffect, useMemo, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import DataService from "../../services/DataService";
import {useRecoilState} from "recoil";
import {user as userAtom} from "../../atoms/user";
import {useParams} from "react-router-dom";
import {emptyEnddat} from "../../util/EmptyObjects";


export default function Enddates() {
    const service = useMemo(() => DataService.getInstance(), []);
    const [loading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useRecoilState(userAtom);
    const [enddate, setEnddate] = useState<Enddates>(emptyEnddat);

    // @ts-ignore
    const params: { saison: number } = useParams();

    useEffect(() => {
        if(user.token === "") {
            setLoading(true);
        } else {
            setLoading(true);
            service.dashboard().getEnddate(params.saison, user.token, setUser, setEnddate, setLoading);
        }
    }, [service, user.token, params.saison, setUser]);

    return (
        <Container className={"greyBg"}>
            {
                loading ?
                    <div className="spinner-grow" role="status"/>
                :
                    <p>
                        <Row>
                            <Col><h5>Endzeiten für Spieltag {enddate.tag}</h5></Col>
                        </Row>
                        <Row>
                            <Col>Wetten: </Col>
                            <Col>{
                                enddate.bet != null ?
                                    new Date(enddate.bet * 1000).toLocaleString()
                                :
                                    "offen"
                            }</Col>
                        </Row>
                        <Row>
                            <Col>Teambearbeiten: </Col>
                            <Col>{
                                enddate.editTeam != null ?
                                    new Date(enddate.editTeam * 1000).toLocaleString()
                                    :
                                    "offen"
                            }</Col>
                        </Row>
                    </p>
            }
        </Container>);
}