import React from "react";
import beardcelonaBreacher from "../asserts/images/beardcelona_breacher/icon.png"
import bitchesOfNaggaroth from "../asserts/images/bitches_of_naggaroth/icon.png"
import bottropsFinest from "../asserts/images/bottrops_finest/icon.png"
import squeakBayRatters from "../asserts/images/squeak_bay_ratters/icon.png"
import starbloodBrawlerz from "../asserts/images/starblood_brawlerz/icon.png"
import furyBully from "../asserts/images/fury_bulls/icon.png"
import purplePeopleBeaters from "../asserts/images/purple_people_beaters/icon.png"
import daGlitzaSpritza from "../asserts/images/da_glitza_spritza/icon.png"
import carogrinaPrancers from "../asserts/images/carogrina_prancers/icon.png"
import mightyNeuzzChamillionairez from "../asserts/images/mighty_neuzz_chamillionairez/icon.png"
import laurelornTreetops from "../asserts/images/laurelorn_treetops/icon.png"
import spacer from "../asserts/images/spacer.png"

const getLogo = (id: number, size: number): React.JSX.Element => {
    switch (id) {
        case 1:
            return <img src={bitchesOfNaggaroth} height={size} width={size} alt={"Logo"}/>
        case 2:
            return <img src={squeakBayRatters} height={size} width={size} alt={"Logo"}/>
        case 3:
            return <img src={beardcelonaBreacher} height={size} width={size} alt={"Logo"}/>
        case 4:
            return <img src={starbloodBrawlerz} height={size} width={size} alt={"Logo"}/>
        case 6:
            return <img src={bottropsFinest} height={size} width={size} alt={"Logo"}/>
        case 8:
            return <img src={daGlitzaSpritza} height={size} width={size} alt={"Logo"}/>
        case 9:
            return <img src={furyBully} height={size} width={size} alt={"Logo"}/>
        case 10:
            return <img src={carogrinaPrancers} height={size} width={size} alt={"Logo"}/>
        case 11:
            return <img src={mightyNeuzzChamillionairez} height={size} width={size} alt={"Logo"}/>
        case 12:
            return <img src={laurelornTreetops} height={size} width={size} alt={"Logo"}/>
        case 13:
            return <img src={purplePeopleBeaters} height={size} width={size} alt={"Logo"}/>
        default:
            return <img src={spacer} height={size} width={size} alt={"Logo"}/>
    }
};
export default function Team(props: TeamProps) {
    let logoSize: number = 50;
    if(props.small === true) {
        logoSize = 30;
    }
    return (
        <>
        {
            props.onlyLogo === true ?
                getLogo(props.team.id, logoSize)
            :
                <>
                    {getLogo(props.team.id, logoSize)}&nbsp;<span>{props.team.name}</span>
                </>
        }
        </>
    );
}