import React, {useEffect, useMemo, useState} from 'react';
import DataService from "../../services/DataService";
import {useParams} from "react-router-dom";
import {WetterByArt} from "../../util/Utils";

const range = (index: number): string => {
    switch(index) {
        case 0:
            return "2";
        case 1:
            return "3";
        case 2:
            return "4-10";
        case 3:
            return "11";
        case 4:
            return "12";
        default:
            return "-";
    }
}

export default function Wetter() {
    const service = useMemo(() => DataService.getInstance(), []);
    const [wetterList, setWetterList] = useState<Wetter[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    // @ts-ignore
    const params: { saison: number, art:number } = useParams();

    useEffect(() => {
        setLoading(true);
        service.staticData().getWetterByArt(params.art, setWetterList, setLoading);
    }, [service, params.art]);

    return (
        <div className="Wetter">
            {
                loading ?
                    <div className="spinner-grow" role="status" />
                    :
                    <div>
                        <h1>Wettertabelle für: {WetterByArt(params.art).name}</h1>
                        <table className="col-sm-12 table">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Beschreibung</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                wetterList.map((wetter, index) =>
                                    <tr key={index}>
                                        <td>{range(index)}</td>
                                        <td>{wetter.name}</td>
                                        <td>{wetter.description}</td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
            }
        </div>
    );
}