import React, {useEffect, useMemo, useState} from "react";
import {Container} from "react-bootstrap";
import DataService from "../../services/DataService";
import {useRecoilState} from "recoil";
import {useParams} from "react-router-dom";
import {user as userAtom} from "../../atoms/user";
import Table from "react-bootstrap/Table";

export default function Events() {
    const service = useMemo(() => DataService.getInstance(), []);
    const [loading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useRecoilState(userAtom);
    const [events, setEvents] = useState<AppEvent[]>([]);

    // @ts-ignore
    const params: { saison: number } = useParams();

    useEffect(() => {
        if(user.token === "") {
            setLoading(true);
        } else {
            setLoading(true);
            service.dashboard().getEvents(params.saison, user.token, setUser, setEvents, setLoading);
        }
    }, [service, user.token, params.saison, setUser]);

    return (
        <Container className={"greyBg"}>
            <h5>Events</h5>
            {
                loading ?
                    <div className="spinner-grow" role="status"/>
                    :
                    <Table striped>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>User</th>
                            <th>Zeit</th>
                            <th>Typ</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            events.map((data, index) =>
                                <tr key={index}>
                                    <td>{index}</td>
                                    <td>{data.user}</td>
                                    <td>{data.time}</td>
                                    <td>{data.typ}</td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
            }
        </Container>);
}