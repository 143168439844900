import React from 'react';
import {useParams} from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import PlayerStatistik from "../../component/PlayerStatistik";
import TeamStatistik from "../../component/TeamStatistik";
import {PlayerStatistikType, TeamStatistikType} from "../../util/enums";

export default function Statistiken() {
    // @ts-ignore
    const params: { saison: number | null } = useParams();
    if(params.saison === undefined) {
        params.saison = null;
    }

    return (
        <div>
            <Row>
                <Col>
                    <PlayerStatistik title={"Meisten Touchdowns"} valueName={"Touchdowns"} saison={params.saison} type={PlayerStatistikType.TOUCHDOWNS} />
                </Col>
                <Col>
                    <PlayerStatistik title={"Meisten VSA"} valueName={"VSA"} saison={params.saison} type={PlayerStatistikType.VSA} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <PlayerStatistik title={"Meisten Pässe"} valueName={"Pässe"} saison={params.saison} type={PlayerStatistikType.PASS} />
                </Col>
                <Col>
                    <PlayerStatistik title={"Most Valuable Players"} valueName={"Verdiente SSP"} saison={params.saison} type={PlayerStatistikType.MVP} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <TeamStatistik title={"Meiste Tode"} valueName={"Tote"} saison={params.saison} type={TeamStatistikType.DEAD} />
                </Col>
                <Col>
                    <TeamStatistik title={"Meiste Einnahmen"} valueName={"Einnahmen"} saison={params.saison} type={TeamStatistikType.INCOME} />
                </Col>
            </Row>
        </div>
    );
}