
export default class BaseService {
    protected readonly baseUrl: string = "https://api.blood-bros-league.de/";
    //protected readonly baseUrl: string = "http://localhost:9090/";

    protected readonly url: string = this.baseUrl + "bros/";

    protected getUrl(saison: number): string {
        return this.url + "saison/"+saison+"/";
    }
}
