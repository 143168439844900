import React, {useEffect, useMemo, useState} from 'react';
import {Card, FloatingLabel} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {useRecoilState} from "recoil";
import {user as userAtom} from "../../atoms/user";
import {useNavigate, useParams} from "react-router-dom";
import DataService from "../../services/DataService";

export default function Login() {
    const service = useMemo(() => DataService.getInstance(), []);
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useRecoilState(userAtom);

    // @ts-ignore
    const params: { saison: number } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(user.token !== "") {
            navigate("/saison/" + params.saison + "/member/dashboard");
        }
    }, [user.token, params.saison, navigate]);

    return (
        <div className="container-fluid h-100">
            <br />
            <br />
            <div className="row justify-content-center align-items-center h-100">
                <div className="col col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <Card>
                        <Card.Header as="h5">Login</Card.Header>
                        <Card.Body>
                            {
                                error && <Card.Subtitle>{error}</Card.Subtitle>
                            }
                            <Form>
                                <FloatingLabel controlId="user" label="Username" className="mb-3">
                                    <Form.Control type="text" placeholder={""} value={username} onChange={e => {
                                        setUsername(e.target.value);
                                        e.preventDefault();
                                    }}/>
                                </FloatingLabel>
                                <FloatingLabel controlId="password" label="Passwort" className="mb-3">
                                    <Form.Control type="password" placeholder={""} value={password} onChange={e => {
                                        setPassword(e.target.value);
                                        e.preventDefault();
                                    }}/>
                                </FloatingLabel>
                                <Button type={"submit"} disabled={loading} onClick={event => {
                                    setLoading(true)
                                    service.login(username, password, setUser, setError, setLoading);
                                }}>Login</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}