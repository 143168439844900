import React, {useEffect, useMemo, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import logo from "../asserts/images/blood_bros_league_logo.png"
import {Link} from "react-router-dom";
import DataService from "../services/DataService";
import {faFootball, faLock, faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const saisonEntry = (saison: Saison):any => {
    if(saison.laeuft) {
        return <><Link to={"/saison/"+saison.id}>{saison.name}</Link>&nbsp;<FontAwesomeIcon icon={faFootball} /></>;
    }
    if(saison.abgeschlossen) {
        return <><Link to={"/saison/"+saison.id}>{saison.name}</Link>&nbsp;<FontAwesomeIcon icon={faLock} /></>;
    }
    return <><Link to={"/saison-preview"}>{saison.name}</Link>&nbsp;<FontAwesomeIcon icon={faEye} /></>;
}

export default function Overview() {
    const service = useMemo(() => DataService.getInstance(), []);
    const [loading, setLoading] = useState<boolean>(false);
    const [saisons, setSaisons] = useState<Saison[]>([]);

    useEffect(() => {
        setLoading(true);
        service.getSaisons(setSaisons, setLoading);
    }, [service]);

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h1 className={"text-center"}>Saison Übersicht</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <img src={logo} className={"mx-auto d-block"} height={300} width={300} alt={"Logo"}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    {
                        loading ?
                            <div className="spinner-grow" role="status" />
                        :
                            saisons.map(saison =>
                                <p key={saison.id} className={"text-center fs-3"}>
                                    {
                                        saisonEntry(saison)
                                    }
                                </p>
                            )
                    }
                </Col>
            </Row>
            <Row>
                <Col><hr/></Col>
            </Row>
            <Row>
                <Col>
                    <p className={"text-center fs-3"}>
                        <Link to={"/statistiken"}>Statistiken</Link>
                    </p>
                </Col>
            </Row>
        </Container>
    );
}