import React, {useEffect, useMemo, useState} from 'react';
import DataService from "../../services/DataService";
import {useParams} from "react-router-dom";
import Team from "../../component/Team";
import {Tabs, Tab} from "react-bootstrap";
import {WetterByArt} from "../../util/Utils";

const rowStyle = (isTag: boolean, isDataTag: boolean): string => {
    // if(isTag && isDataTag) {
    //     return "current";
    // } else if(isTag) {
    //     return "currentDay";
    // } else if(isDataTag) {
    //     return "currentData";
    // }
    return "";
}

function printSpielTage(saison: number, id: number, kw:string, tag:number, datum: string, wetterArt:number, isTag: boolean, isDataTag:boolean, paarungen: SpieltagPaarung[]): any {
    return <>
        <tr key={id} className={rowStyle(isTag, isDataTag)}>
            <td rowSpan={paarungen.length}>{kw}</td>
            <td rowSpan={paarungen.length}>{datum}</td>
            <td rowSpan={paarungen.length}>
                <a href={"/saison/"+saison+"/wetter/"+wetterArt}>{WetterByArt(wetterArt).name}</a>
            </td>
            <td>{
                    paarungen[0].heim != null &&
                    <Team team={paarungen[0].heim} />
                }
            </td>
            <td>{
                    paarungen[0].gast != null &&
                    <Team team={paarungen[0].gast} />
                }
            </td>
            <td>
                {
                    // eslint-disable-next-line eqeqeq
                    paarungen[0].heim.id == 0 || paarungen[0].gast.id == 0 ?
                        <>
                            &nbsp;
                        </>
                        :
                        <>
                        {
                            paarungen[0].heim.touchdown === null && paarungen[0].gast.touchdown === null ?
                                <span>- : -</span>
                                :
                                <span>{paarungen[0].heim.touchdown} : {paarungen[0].gast.touchdown}&nbsp;&nbsp;<a href={"/saison/" + saison + "/spielbericht/" + tag + "/" + paarungen[0].heim.id + "/" + paarungen[0].gast.id}>Bericht</a></span>
                        }
                        </>
                }
            </td>
        </tr>
        {
            paarungen.length > 1 &&
            <>
                <tr className={rowStyle(isTag, isDataTag)}>
                    <td>{
                        paarungen[1].heim != null &&
                        <Team team={paarungen[1].heim}/>
                    }
                    </td>
                    <td>{
                        paarungen[1].gast != null &&
                        <Team team={paarungen[1].gast}/>
                    }
                    </td>
                    <td>
                        {
                            // eslint-disable-next-line eqeqeq
                            paarungen[1].heim.id == 0 || paarungen[1].gast.id == 0 ?
                                <>
                                    &nbsp;
                                </>
                                :
                                <>
                                    {
                                        paarungen[1].heim.touchdown === null && paarungen[1].gast.touchdown === null ?
                                            <span>- : -</span>
                                            :
                                            <span>{paarungen[1].heim.touchdown} : {paarungen[1].gast.touchdown}&nbsp;&nbsp;<a href={"/saison/" + saison + "/spielbericht/" + tag + "/" + paarungen[1].heim.id + "/" + paarungen[1].gast.id}>Bericht</a></span>
                                    }
                                </>
                        }
                    </td>
                </tr>
            </>
        }
        {
            paarungen.length > 2 &&
            <>
                <tr className={rowStyle(isTag, isDataTag)}>
                    <td>{paarungen[2].heim != null && <Team team={paarungen[2].heim}/>}</td>
                    <td>{paarungen[2].gast != null && <Team team={paarungen[2].gast}/>}</td>
                    <td>
                        {
                            // eslint-disable-next-line eqeqeq
                            paarungen[2].heim.id == 0 || paarungen[2].gast.id == 0 ?
                                <>
                                    &nbsp;
                                </>
                                :
                                <>
                                {
                                    paarungen[2].heim.touchdown === null && paarungen[2].gast.touchdown === null ?
                                        <span>- : -</span>
                                        :
                                        <span>{paarungen[2].heim.touchdown} : {paarungen[2].gast.touchdown}&nbsp;&nbsp;<a href={"/saison/" + saison + "/spielbericht/" + tag + "/" + paarungen[2].heim.id + "/" + paarungen[2].gast.id}>Bericht</a></span>
                                }
                                </>
                        }
                    </td>
                </tr>
            </>
        }
        {
            paarungen.length > 3 &&
            <>
                <tr className={rowStyle(isTag, isDataTag)}>
                    <td>{paarungen[3].heim != null && <Team team={paarungen[3].heim}/>}</td>
                    <td>{paarungen[3].gast != null && <Team team={paarungen[3].gast}/>}</td>
                    <td>
                        {
                            // eslint-disable-next-line eqeqeq
                            paarungen[3].heim.id == 0 || paarungen[3].gast.id == 0 ?
                                <>
                                    &nbsp;
                                </>
                                :
                                <>
                                {
                                    paarungen[3].heim.touchdown === null && paarungen[3].gast.touchdown === null ?
                                        <span>- : -</span>
                                        :
                                        <span>{paarungen[3].heim.touchdown} : {paarungen[3].gast.touchdown}&nbsp;&nbsp;<a href={"/saison/" + saison + "/spielbericht/" + tag + "/" + paarungen[3].heim.id + "/" + paarungen[3].gast.id}>Bericht</a></span>
                                }
                                </>
                        }
                    </td>
                </tr>
            </>
        }
    </>
}

export default function Spielplan() {
    const service = useMemo(() => DataService.getInstance(), []);
    const [spieltageData, setSpieltageData] = useState<LigaRunde[]>([]);
    const [spieltag, setSpieltag] = useState<Spieltag>({tag:0, tagDaten: 0});
    const [loading, setLoading] = useState<boolean>(false);
    // @ts-ignore
    const params: { saison: number } = useParams();

    useEffect(() => {
        setLoading(true);
        service.spieltag().getTag(params.saison, setSpieltag, (b) => b);
        service.spieltag().getTage(params.saison, setSpieltageData, setLoading);
    }, [service, params.saison]);

    return (
        <div className="Overview">
            {
                loading ?
                    <div className="spinner-grow" role="status" />
                :
                    <Tabs className="mb-3" justify>
                        {
                            spieltageData.map(data =>
                                <Tab key={data.typ} eventKey={data.typ} title={data.typ}>
                                    <table className="col-sm-12 table table-bordered table-striped table-sm">
                                        <thead>
                                        <tr>
                                            <th>KW</th>
                                            <th>Zeitraum</th>
                                            <th>Wetter</th>
                                            <th>Heim</th>
                                            <th>Gast</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            data.tage.map(tag => printSpielTage(params.saison, tag.id, tag.kw, tag.tag, tag.datum, tag.wetterId,
                                                tag.tag===spieltag.tag, tag.tag===spieltag.tagDaten, tag.paarungen))
                                        }
                                        </tbody>
                                    </table>
                                </Tab>
                            )
                        }
                    </Tabs>
            }
        </div>
    );
}