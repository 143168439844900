export enum PlayerStatistikType {
    VSA,
    TOUCHDOWNS,
    PASS,
    MVP
}

export enum TeamStatistikType {
    DEAD,
    INCOME
}