import React, {useEffect} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {useRecoilState} from "recoil";
import {user as userAtom} from "../../../atoms/user";
import TeamInfo from "../../../component/dashboard/TeamInfo";
import Gegner from "../../../component/dashboard/Gegner";
import Wetten from "../../../component/dashboard/Wetten";
import Berichte from "../../../component/dashboard/Berichte";
import Events from "../../../component/dashboard/Events";
import Enddates from "../../../component/dashboard/Enddates";

export default function Dashboard() {

    const [user, setUser] = useRecoilState(userAtom);

    // @ts-ignore
    const params: { saison: number } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(user.token === "") {
            navigate("/saison/" + params.saison + "/login");
        }
    }, [user.token, params.saison, navigate, setUser]);

    return (
        <Container>
            <h1>Dashboard</h1>
            <p>
                <strong>Hallo {user.name} hier eine aktuelle Übersicht deiner Saison {params.saison}</strong>
            </p>
            {
                user.rolle === "Admin" &&
                <Row>
                    <Col>
                        <Berichte />
                    </Col>
                    <Col>
                        <Events />
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    <Gegner/>
                </Col>
                <Col>
                    <Enddates/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Wetten />
                </Col>
            </Row>
            <Row>
                <Col>
                    <TeamInfo />
                </Col>
            </Row>
        </Container>
    );
}