import React, {useEffect, useMemo, useState} from "react";
import {Container} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import DataService from "../services/DataService";
import {PlayerStatistikType} from "../util/enums";

export default function PlayerStatistik(props: PlayerStatistikProps) {
    const service = useMemo(() => DataService.getInstance(), []);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<PlayerStatistikData[]>([]);

    useEffect(() => {
        setLoading(true);
        switch (props.type) {
            case PlayerStatistikType.VSA:
                service.statistik().getVSA(props.saison, setData, setLoading);
                break;
            case PlayerStatistikType.TOUCHDOWNS:
                service.statistik().getTouchDowns(props.saison, setData, setLoading);
                break;
            case PlayerStatistikType.PASS:
                service.statistik().getPasse(props.saison, setData, setLoading);
                break;
            case PlayerStatistikType.MVP:
                service.statistik().getMvp(props.saison, setData, setLoading);
                break;
        }
    }, [service, props.type, props.saison]);

    return (
        <Container className={"greyBg"}>
            {
                loading ?
                    <div className="spinner-grow" role="status" />
                :
                    <Table striped size="sm" className={"caption-top"}>
                        <caption>{props.title}</caption>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Team</th>
                                {
                                    props.saison === null && <th>Saison</th>
                                }
                                <th>{props.valueName}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            data.map((d, index) =>
                                <tr key={index}>
                                    <td>{d.pos}</td>
                                    <td>{d.player.name}</td>
                                    <td>{d.team.name}</td>
                                    {
                                        props.saison === null && <td>{d.saison}</td>
                                    }
                                    <td>{d.value}</td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
            }
        </Container>
    );
}