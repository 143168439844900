import React from "react";
import { Container} from "react-bootstrap";

export default function Berichte() {

    return (
        <Container className={"greyBg"}>
            <p>
                Hier wird eine Liste von unverarbeitet Spielberichten angezeigt
            </p>
        </Container>);
}