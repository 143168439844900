import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Team from "./Team";

export default function NewStaffModal(props: StaffModalProps) {

    const staffList: Staff[] = [
        {
            amount: 1,
            teamId: 0,
            kosten: 10000,
            name: "Cheerleader",
            type: "CHEERLEADER"
        },
        {
            amount: 1,
            teamId: 0,
            kosten: 50000,
            name: "Sanitäter",
            type: "DOC"
        },
        {
            amount: 1,
            teamId: 0,
            kosten: 1,
            name: "Wiederholungswürfe",
            type: "REROLL"
        },
        {
            amount: 1,
            teamId: 0,
            kosten: 10000,
            name: "Trainerassistent",
            type: "TRAINER_ASSISTANT"
        }
    ]

    return (
        <Modal show={props.show}
               onHide={props.handleClose}
               backdrop="static"
               keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Neue Mitarbeiter Anheuern & ReRolls</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped hover={true}>
                    <thead>
                    <tr>
                        <th>Team</th>
                        <th>Typ</th>
                        <th>Kosten</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        staffList.map(staff =>
                            <>
                                <tr key={staff.type+"_1"} onClick={e => {
                                    staff.teamId = props.heimId
                                    if(staff.type === "REROLL") {
                                        staff.kosten = props.heimReroll * 2;
                                    }
                                    props.handleAdd(staff)
                                }}>
                                    <td>
                                        <Team team={{id: props.heimId, name: ''}} small={true} onlyLogo={true}/>
                                    </td>
                                    <td>{staff.name}</td>
                                    <td>{
                                        staff.type === "REROLL" ?
                                            props.heimReroll * 2
                                        :
                                            staff.kosten
                                    }</td>
                                </tr>
                                {
                                    props.gastId != null && props.gastReroll != null &&
                                    <tr key={staff.type+"_2"} onClick={e => {
                                        // @ts-ignore
                                        staff.teamId = props.gastId
                                        if(staff.type === "REROLL") {
                                            // @ts-ignore
                                            staff.kosten = props.gastReroll * 2;
                                        }
                                        props.handleAdd(staff)
                                    }}>
                                        <td>
                                            <Team team={{id: props.gastId, name: ''}} small={true} onlyLogo={true}/>
                                        </td>
                                        <td>{staff.name}</td>
                                        <td>{
                                            staff.type === "REROLL" ?
                                                props.gastReroll * 2
                                                :
                                                staff.kosten
                                        }</td>
                                    </tr>
                                }
                            </>
                        )
                    }
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}