import BaseService from "./BaseService";

export default class SpieltagService extends BaseService {

    public getTag(saison: number, setData: (p:Spieltag) => void, setLoading: (p:boolean) => void): void {
        fetch(this.getUrl(saison)+"spieltag")
            .then(r => r.json() as Promise<ApiResponse<Spieltag>>)
            .then(r => setData(r.data))
            .finally(() => setLoading(false));
    }

    public getTage(saison: number, setData: (p:LigaRunde[]) => void, setLoading: (p:boolean) => void): void {
        fetch(this.getUrl(saison)+"spieltag/list")
            .then(r => r.json() as Promise<ApiResponse<LigaRunde[]>>)
            .then(r => setData(r.data))
            .finally(() => setLoading(false));
    }

    public getPaarungen(saison: number, tag: number, setData: (p:SpieltagPaarung[]) => void, setLoading: (p:boolean) => void): void {
        fetch(this.getUrl(saison)+"spieltag/"+tag+"/parrung")
            .then(r => r.json() as Promise<ApiResponse<SpieltagPaarung[]>>)
            .then(r => setData(r.data))
            .finally(() => setLoading(false));
    }
}