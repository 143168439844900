import React, {useEffect, useMemo, useState} from 'react';
import DataService from "../../services/DataService";
import {useParams} from "react-router-dom";
import Team from "../../component/Team";

export default function Overview() {
    const service = useMemo(() => DataService.getInstance(), []);
    const [overviewData, setOverviewData] = useState<OverviewData>({spieltag: 0, teams: []});
    const [saisonResult, setSaisonResult] = useState<SaisonResult>({platz_1: null, platz_2: null, platz_3: null});
    const [spieltag, setSpieltag] = useState<Spieltag>({tag:0, tagDaten: 0});
    const [loading, setLoading] = useState<boolean>(false);
    // @ts-ignore
    const params: { saison: number } = useParams();

    useEffect(() => {
        setLoading(true);
        service.spieltag().getTag(params.saison, setSpieltag, (b) => b);
        service.getSaisonResult(params.saison, setSaisonResult, (b) => b);
        service.getOverview(params.saison, setOverviewData, setLoading);
    }, [service, params.saison]);

    return (
        <div className="Overview">
            {
                loading ?
                    <div className="spinner-grow" role="status" />
                :
                    <div>
                        {
                            saisonResult.platz_1 != null && saisonResult.platz_2 != null && saisonResult.platz_3 != null &&
                                <div>
                                    <h1>Ergebnis der Saison</h1>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Team</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1.</td>
                                                <td><Team team={saisonResult.platz_1}/></td>
                                            </tr>
                                            <tr>
                                                <td>2.</td>
                                                <td><Team team={saisonResult.platz_2}/></td>
                                            </tr>
                                            <tr>
                                                <td>3.</td>
                                                <td><Team team={saisonResult.platz_3}/></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                        }
                        <h1>Ligatabelle nach dem {spieltag.tag}. Spieltag</h1>
                        <table className="col-sm-12 table">
                            <thead>
                                <tr>
                                    <th>Pos.</th>
                                    <th>Team</th>
                                    <th>Trainer</th>
                                    <th>Ligapunkte</th>
                                    <th>Sieg</th>
                                    <th>Niederlage</th>
                                    <th>Unent.</th>
                                    <th>Absolvierte Spiele</th>
                                    <th>Touchdowns</th>
                                    <th>VSA</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    overviewData.teams.map(team =>
                                        <tr key={team.id}>
                                            <td>{team.pos!}</td>
                                            <td><Team team={team} small={true}/></td>
                                            <td>{team.trainer}</td>
                                            <td>{team.punkte}</td>
                                            <td>{team.siege}</td>
                                            <td>{team.niederlagen}</td>
                                            <td>{team.unentschieden}</td>
                                            <td>{team.spiele}</td>
                                            <td>{team.touchdowns}</td>
                                            <td>{team.vsa}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
            }
        </div>
    );
}