import React, {useEffect, useMemo, useState} from 'react';
import {useRecoilState} from "recoil";
import {user as userAtom} from "../../../atoms/user";
import {useNavigate, useParams} from "react-router-dom";
import DataService from "../../../services/DataService";
import Table from "react-bootstrap/Table";
import Team from "../../../component/Team"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {emptyTeamOverview} from "../../../util/EmptyObjects";

const deActivated = (endDate: number): boolean => {
    const now = Math.floor(Date.now() / 1000);
    if(endDate === 0) {
        return false;
    }
    return endDate < now;
}

export default function Wetten() {
    const service = useMemo(() => DataService.getInstance(), []);
    const [loading, setLoading] = useState<boolean>(false);
    const [wetten, setWetten] = useState<WettOverview[]>([]);
    const [team, setTeam] = useState<TeamOverview>(emptyTeamOverview);
    const [error, setError] = useState<string | null>(null);
    const [user, setUser] = useRecoilState(userAtom);

    // @ts-ignore
    const params: { saison: number } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(user.token === "") {
            navigate("/saison/" + params.saison + "/login");
        } else {
            setLoading(true);
            service.bet().getOverview(params.saison, user.token, setUser, setWetten, setLoading);
            service.dashboard().getTeamtData(params.saison, user.token, setUser, setTeam, b => b);
        }
    }, [user.token, params.saison, navigate, setUser, service]);

    return (
        <div>
            <h1>Wetten</h1>
            {
                loading ?
                    <div className="spinner-grow" role="status"/>
                :
                    <>
                        <p>
                            Teamkasse: {team.kasse}
                        </p>
                        <Table striped>
                            <thead>
                            <tr>
                                <th>Spieltag</th>
                                <th>Heim</th>
                                <th>Gast</th>
                                <th>&nbsp;</th>
                                <th>Heim Punkte</th>
                                <th>Gast Punkte</th>
                                <th>Einsatz</th>
                                <th>&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                error !== null &&
                                <tr>
                                    <td colSpan={8}><span className={"textRed"}>{error}</span></td>
                                </tr>
                            }
                            {
                                wetten.map(wette =>
                                    wette.wetten.map((w, index) =>
                                        <tr key={index}>
                                            {index === 0 && <td rowSpan={wette.wetten.length}>{wette.spieltag}</td>}
                                            <td><Team team={w.heim}/></td>
                                            <td><Team team={w.gast}/></td>
                                            {
                                                index === 0 &&
                                                    <td rowSpan={wette.wetten.length}>
                                                        <span>Kombi&nbsp;</span>
                                                        <Form.Check.Input disabled={deActivated(wette.endDate)}
                                                                          defaultChecked={wette.kombi}
                                                                          title={"Kombiwette"}
                                                                          onChange={event => wette.kombi = event.target.checked}
                                                        />
                                                    </td>
                                            }
                                            <td>
                                                <Form.Control type="number" disabled={deActivated(wette.endDate)} min={0} step={1} defaultValue={w.heimPunkte===null?0:w.heimPunkte} onChange={event => w.heimPunkte = parseInt(event.target.value)} />
                                            </td>
                                            <td>
                                                <Form.Control type="number" disabled={deActivated(wette.endDate)} min={0} step={1} defaultValue={w.gastPunkte===null?0:w.gastPunkte} onChange={event => w.gastPunkte = parseInt(event.target.value)} />
                                            </td>
                                            <td>
                                                <Form.Control type="number" disabled={deActivated(wette.endDate)} min={10000} max={50000} defaultValue={w.einsatz} onChange={event => w.einsatz = parseInt(event.target.value)} />
                                            </td>
                                            {
                                                index === 0 &&
                                                <td rowSpan={wette.wetten.length}>
                                                    <Button variant="primary" disabled={deActivated(wette.endDate)} size="sm" onClick={(e) => {
                                                        setLoading(true);
                                                        setError(null);
                                                        service.bet().save(params.saison, wette, setLoading, user.token, setError);
                                                    }}>
                                                        <FontAwesomeIcon icon={faCheck}/>
                                                    </Button>
                                                </td>
                                            }
                                        </tr>
                                    ))
                            }
                            </tbody>
                        </Table>
                    </>
            }
        </div>);
}