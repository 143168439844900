import BaseService from "./BaseService";

export default class AdminService extends BaseService {

    public clearCache(saison: number, token: string, setUser: (u:User) => void, setData: (p:string) => void, setLoading: (p:boolean) => void): void {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison) + "admin/clearCache",{
                headers: {
                    'Accept': 'application/json',
                    'Authorization': authToken
                }
            }
            )
            .then(r => r.json() as Promise<ApiResponse<string>>)
            .then(r => setData(r.data))
            .catch(e => setUser({token: "", name: "", rolle: ""}))
            .finally(() => setLoading(false));
    }

    public getServerInfo(setData: (p:ActuatorRes[]) => void, setLoading: (p:boolean) => void) {
        const endpoints= ["application.ready.time","hikaricp.connections", "jvm.memory.used", "jvm.memory.max",
            "jvm.info", "process.cpu.usage", "process.uptime", "system.cpu.usage"];

        const res: Promise<ActuatorRes>[] = [];

        endpoints.forEach(endpoint => {
            res.push(this.loadInfo(endpoint));
        });

        Promise.all(res).then(data => {
            setData(data);
            setLoading(false);
        });
    }

    public getEvents(saison: number, token: string, setUser: (u:User) => void, setData: (p:AppEvent[]) => void, setLoading: (p:boolean) => void) {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison)+"admin/events", {
            headers: {
                'Accept': 'application/json',
                'Authorization': authToken
            }
        })
            .then(r => {
                if(r.ok) {
                    return r.json() as Promise<ApiResponse<AppEvent[]>>;
                } else {
                    return Promise.reject(r);
                }
            })
            .then(r => setData(r.data))
            .catch(e => setUser({token: "", name: "", rolle: ""}))
            .finally(() => setLoading(false));
    }

    public getEnddates(saison: number, token: string, setUser: (u:User) => void, setData: (p:Enddates[]) => void, setLoading: (p:boolean) => void) {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison)+"admin/enddates", {
            headers: {
                'Accept': 'application/json',
                'Authorization': authToken
            }
        })
            .then(r => {
                if(r.ok) {
                    return r.json() as Promise<ApiResponse<Enddates[]>>;
                } else {
                    return Promise.reject(r);
                }
            })
            .then(r => setData(r.data))
            .catch(e => setUser({token: "", name: "", rolle: ""}))
            .finally(() => setLoading(false));
    }

    public updateBetEnddate(saison: number, token: string, setUser: (u:User) => void, id: number, data: number, setData: (p:string) => void, setLoading: (p:boolean) => void): void {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison)+"admin/enddate/bet/"+id, {
            headers: {
                'Authorization': authToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "PUT",
            body: JSON.stringify(data)}
        ).then(r => r.json() as Promise<ApiResponse<string>>)
        .then(r => setData(r.data))
        .catch(e => setUser({token: "", name: "", rolle: ""}))
        .finally(() => setLoading(false));
    }

    public updateEditTeamEnddate(saison: number, token: string, setUser: (u:User) => void, id: number, data: number, setData: (p:string) => void, setLoading: (p:boolean) => void): void {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison)+"admin/enddate/team/"+id, {
            headers: {
                'Authorization': authToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "PUT",
            body: JSON.stringify(data)}
        ).then(r => r.json() as Promise<ApiResponse<string>>)
            .then(r => setData(r.data))
            .catch(e => setUser({token: "", name: "", rolle: ""}))
            .finally(() => setLoading(false));
    }

    public getPlayerUpdates(saison: number, token: string, setUser: (u:User) => void, setData: (p:PlayerUpdate[]) => void, setLoading: (p:boolean) => void) {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison)+"admin/playerupdates", {
            headers: {
                'Accept': 'application/json',
                'Authorization': authToken
            }
        })
        .then(r => {
            if(r.ok) {
                return r.json() as Promise<ApiResponse<PlayerUpdate[]>>;
            } else {
                return Promise.reject(r);
            }
        })
        .then(r => setData(r.data))
        .catch(e => setUser({token: "", name: "", rolle: ""}))
        .finally(() => setLoading(false));
    }

    private loadInfo(endpoint: string): Promise<ActuatorRes> {
        return fetch(this.baseUrl + "actuator/metrics/"+endpoint)
            .then(r => r.json() as Promise<ActuatorRes>);
    }
}