import React, {useEffect, useMemo, useState} from 'react';
import {Card, Container, FloatingLabel} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {useRecoilState} from "recoil";
import {user as userAtom} from "../../../atoms/user";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import DataService from "../../../services/DataService";

export default function User() {
    const service = useMemo(() => DataService.getInstance(), []);
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useRecoilState(userAtom);

    // @ts-ignore
    const params: { saison: number } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(user.token === "") {
            navigate("/saison/" + params.saison + "/login");
        }
    }, [user.token, params.saison, navigate, setUser]);

    return (
        <Container>
            <br/>
            <br/>
            <div className="row justify-content-center align-items-center h-100">
                <div className="col col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <Card>
                        <Card.Header as="h5">User Daten</Card.Header>
                        <Card.Body>
                            {
                                error && <Card.Subtitle>{error}</Card.Subtitle>
                            }
                            <Form>
                                <FloatingLabel controlId="password" label="Neues Passwort" className="mb-3">
                                    <Form.Control type="password" placeholder={""} value={password} onChange={e => {
                                        setPassword(e.target.value);
                                        e.preventDefault();
                                    }}/>
                                </FloatingLabel>
                                <Button disabled={loading} onClick={event => {
                                    setLoading(true)
                                    service.updatePassword(params.saison, user.token, password, setUser, setError, setLoading);
                                    setPassword("");
                                }}>Speichern</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </Container>
    );
}