import React, {useEffect, useMemo, useState} from 'react';
import {Container} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {useRecoilState} from "recoil";
import {user as userAtom} from "../../../atoms/user";
import DataService from "../../../services/DataService";
import Table from "react-bootstrap/Table";

export default function TreasuryLog() {

    const service = useMemo(() => DataService.getInstance(), []);
    const [user, setUser] = useRecoilState(userAtom);
    const [loading, setLoading] = useState<boolean>(false);
    const [treasuryRecord, setTreasuryRecord] = useState<TreasuryRecord[]>([]);

    // @ts-ignore
    const params: { saison: number } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(user.token === "") {
            navigate("/saison/" + params.saison + "/login");
        } else {
            setLoading(false);
            service.treasury().getOverview(params.saison, user.token, setUser, setTreasuryRecord, setLoading);
        }
    }, [service, user.token, params.saison, navigate, setUser]);

    return (
        <Container>
            <h1>Kassenbuch</h1>
            <Table striped>
                <thead>
                    <tr>
                        <th>Spieltag</th>
                        <th>&nbsp;</th>
                        <th>Datum</th>
                        <th>Zweck</th>
                        <th>Gold</th>
                        {/*<th>Kasse</th>*/}
                    </tr>
                </thead>
                <tbody>
                {
                    loading ?
                        <tr>
                            <td colSpan={5}>
                                <div className="spinner-grow" role="status"/>
                            </td>
                        </tr>
                        :
                        treasuryRecord.map(t =>
                            t.entries.map((entry,i) =>
                                i >= 1 ?
                                    <tr>
                                        <td>{entry.type}</td>
                                        <td>{new Date(entry.timestamp * 1000).toLocaleString()}</td>
                                        <td>{entry.message}</td>
                                        <td><span className={entry.type === '+' ? 'textGreen' : 'textRed'}>{entry.amount}</span></td>
                                        {/*<td>{entry.total}</td>*/}
                                    </tr>
                                :
                                    <tr>
                                        <td rowSpan={t.entries.length}>{t.spieltag}</td>
                                        <td>{entry.type}</td>
                                        <td>{new Date(entry.timestamp * 1000).toLocaleString()}</td>
                                        <td>{entry.message}</td>
                                        <td><span className={entry.type === '+' ? 'textGreen' : 'textRed'}>{entry.amount}</span></td>
                                        {/*<td>{entry.total}</td>*/}
                                    </tr>
                            )
                        )
                }
                </tbody>
            </Table>
        </Container>
    );
}