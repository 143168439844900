import React, {useEffect, useMemo, useState} from 'react';
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {useRecoilState} from "recoil";
import {user as userAtom} from "../../../atoms/user";
import Button from "react-bootstrap/Button";
import DataService from "../../../services/DataService";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";

const toDTL = (sec:number):string => new Date(sec*1000).toISOString().slice(0, -1);
const fromDTL = (value:string):number => new Date(value).valueOf()/1000;

export default function Admin() {
    const service = useMemo(() => DataService.getInstance(), []);
    const [loading, setLoading] = useState<boolean>(false);
    const [serverInfo, setServerInfo] = useState<ActuatorRes[]>([]);
    const [enddates, setEnddate] = useState<Enddates[]>([]);
    const [user, setUser] = useRecoilState(userAtom);

    // @ts-ignore
    const params: { saison: number } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(user.token === "") {
            navigate("/saison/" + params.saison + "/login");
        } else {
            setLoading(true);
            service.admin().getServerInfo(setServerInfo, setLoading);
            service.admin().getEnddates(params.saison, user.token, setUser, setEnddate, b => b);
        }
    }, [user.token, params.saison, navigate, setUser, service]);

    return (
        <div className="Overview">
            <Tabs defaultActiveKey="cache" className="mb-3" justify>
                <Tab eventKey="cache" title="Cache">
                    <Row>
                        <Col>
                            <Button variant="primary" size="sm" onClick={(e) => {
                                setLoading(true);
                                service.admin().clearCache(params.saison, user.token, setUser, (s) => alert(s), setLoading);
                            }}>Cache löschen
                            </Button>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="config" title="Konfiguration">
                    <h2>Setzen der End Zeiten</h2>
                    <Table striped>
                        <thead>
                        <tr>
                            <th>Spieltag</th>
                            <th>Wett Ende</th>
                            <th>&nbsp;</th>
                            <th>Team Edit Ende</th>
                            <th>&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            enddates.map(e =>
                                <tr>
                                    <td>{e.tag}</td>
                                    <td>
                                        <Form.Control type="datetime-local" defaultValue={e.bet ? toDTL(e.bet) : undefined}
                                                      onChange={event => e.bet = fromDTL(event.target.value)}/>
                                    </td>
                                    <td>
                                        <Button variant="primary" size="sm" onClick={(event) => {
                                            if(e.bet) {
                                                service.admin().updateBetEnddate(params.saison, user.token, setUser, e.id, e.bet, e => alert(e), b => b);
                                            } else {
                                                alert("Setze erst ein Datum");
                                            }
                                        }}>
                                            <FontAwesomeIcon icon={faSave}/>
                                        </Button>
                                    </td>
                                    <td>
                                        <Form.Control type="datetime-local"
                                                      defaultValue={e.editTeam ? toDTL(e.editTeam) : undefined}
                                                      onChange={event => e.editTeam = fromDTL(event.target.value)}/>
                                    </td>
                                    <td>
                                        <Button variant="primary" size="sm" onClick={(event) => {
                                            if(e.editTeam) {
                                                service.admin().updateEditTeamEnddate(params.saison, user.token, setUser, e.id, e.editTeam, e => alert(e), b => b);
                                            }  else {
                                                alert("Setze erst ein Datum");
                                            }
                                        }}>
                                            <FontAwesomeIcon icon={faSave}/>
                                        </Button>
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </Tab>
                <Tab eventKey="info" title="ServerInfos">
                    <h2>ServerInfos</h2>
                    {
                        loading ?
                            <div className="spinner-grow" role="status"/>
                            :
                            <Row>
                                <Col>
                                    {
                                        serverInfo.map(info =>
                                            <Row>
                                                <Row>
                                                    <Col><strong>Name:</strong></Col>
                                                    <Col>{info.name}</Col>
                                                </Row>
                                                <Row>
                                                    <Col><strong>Beschreibung:</strong></Col>
                                                    <Col>{info.description}</Col>
                                                </Row>
                                                <Row>
                                                    <Col><strong>Einheit:</strong></Col>
                                                    <Col>{info.baseUnit}</Col>
                                                </Row>
                                                <Row>
                                                    <Col><strong>Messwert:</strong></Col>
                                                    <Col>
                                                        {
                                                            info.measurements.map(m =>
                                                                <>
                                                                    <Row>
                                                                        <Col><strong>Statistic:</strong></Col>
                                                                        <Col>{m.statistic}</Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col><strong>Wert:</strong></Col>
                                                                        <Col>{m.value}</Col>
                                                                    </Row>
                                                                </>
                                                            )
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col><strong>Tags:</strong></Col>
                                                    <Col>
                                                        {
                                                            info.availableTags.map(a =>
                                                                <>
                                                                    <Row>
                                                                        <Col><strong>Tag:</strong></Col>
                                                                        <Col>{a.tag}</Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col><strong>values:</strong> </Col>
                                                                        <Col>{a.values}</Col>
                                                                    </Row>
                                                                </>
                                                            )
                                                        }
                                                    </Col>
                                                </Row>
                                            </Row>
                                        )
                                    }
                                </Col>
                            </Row>
                    }
                </Tab>
            </Tabs>
        </div>
    );
}