import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Team from "./Team";

export default function NewSPPModal(props: SspModalProps) {

    return (
        <Modal show={props.show}
               onHide={props.handleClose}
               backdrop="static"
               keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Neue Spieler Anheuern</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped hover={true}>
                    <thead>
                    <tr>
                        <tr>
                            <th>Name</th>
                            <th>SSP</th>
                        </tr>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.player.map(player =>
                            <tr key={player.id} onClick={e => props.handleAdd(player)}>
                                <td>
                                    {player.teamId &&
                                        <Team team={{id: player.teamId, name: ''}} small={true} onlyLogo={true}/>}
                                    {player.name}
                                </td>
                                <td>{player.ssp}</td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}