import BaseService from "./BaseService";

export default class TreasuryService extends BaseService {

    public getOverview(saison: number, token: string, setUser: (u:User) => void, setData: (d: TreasuryRecord[]) => void, setLoading: (p:boolean) => void): void {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison)+"member/treasury", {
            headers: {
                'Accept': 'application/json',
                'Authorization': authToken
            }
        })
        .then(r => {
            if(r.ok) {
                return r.json() as Promise<ApiResponse<TreasuryRecord[]>>;
            } else {
                return Promise.reject(r);
            }
        })
        .then(r => setData(r.data))
        .catch(e => setUser({token: "", name: "", rolle: ""}))
        .finally(() => setLoading(false));
    }

}