import BaseService from "./BaseService";

export default class StatistikService extends BaseService {

    public getTouchDowns(saison: number | null, setData: (p:PlayerStatistikData[]) => void, setLoading: (p:boolean) => void): void {
        const url = saison !== null ? this.getUrl(saison) : this.url;
        fetch(url + "stats/touchdowns")
            .then(r => r.json() as Promise<ApiResponse<PlayerStatistikData[]>>)
            .then(r => setData(r.data))
            .finally(() => setLoading(false));
    }

    public getVSA(saison: number | null, setData: (p:PlayerStatistikData[]) => void, setLoading: (p:boolean) => void): void {
        const url = saison !== null ? this.getUrl(saison) : this.url;
        fetch(url + "stats/vsa")
            .then(r => r.json() as Promise<ApiResponse<PlayerStatistikData[]>>)
            .then(r => setData(r.data))
            .finally(() => setLoading(false));
    }

    public getPasse(saison: number | null, setData: (p:PlayerStatistikData[]) => void, setLoading: (p:boolean) => void): void {
        const url = saison !== null ? this.getUrl(saison) : this.url;
        fetch(url + "stats/pass")
            .then(r => r.json() as Promise<ApiResponse<PlayerStatistikData[]>>)
            .then(r => setData(r.data))
            .finally(() => setLoading(false));
    }

    public getMvp(saison: number | null, setData: (p:PlayerStatistikData[]) => void, setLoading: (p:boolean) => void): void {
        const url = saison !== null ? this.getUrl(saison) : this.url;
        fetch(url + "stats/mvp")
            .then(r => r.json() as Promise<ApiResponse<PlayerStatistikData[]>>)
            .then(r => setData(r.data))
            .finally(() => setLoading(false));
    }

    public getTote(saison: number | null, setData: (p:TeamStatistikData[]) => void, setLoading: (p:boolean) => void): void {
        const url = saison !== null ? this.getUrl(saison) : this.url;
        fetch(url + "stats/dead")
            .then(r => r.json() as Promise<ApiResponse<TeamStatistikData[]>>)
            .then(r => setData(r.data))
            .finally(() => setLoading(false));
    }

    public getIncome(saison: number | null, setData: (p:TeamStatistikData[]) => void, setLoading: (p:boolean) => void): void {
        const url = saison !== null ? this.getUrl(saison) : this.url;
        fetch(url + "stats/income")
            .then(r => r.json() as Promise<ApiResponse<TeamStatistikData[]>>)
            .then(r => setData(r.data))
            .finally(() => setLoading(false));
    }

}