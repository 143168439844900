import BaseService from "./BaseService";

export default class BetService extends BaseService {

    public getOverview(saison: number, token: string, setUser: (u:User) => void, setData: (d: WettOverview[]) => void, setLoading: (p:boolean) => void): void {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison)+"member/bet", {
            headers: {
                'Accept': 'application/json',
                'Authorization': authToken
            }
        })
        .then(r => {
            if(r.ok) {
                return r.json() as Promise<ApiResponse<WettOverview[]>>;
            } else {
                return Promise.reject(r);
            }
        })
        .then(r => setData(r.data))
        .catch(e => setUser({token: "", name: "", rolle: ""}))
        .finally(() => setLoading(false));
    }

    public save(saison: number, data: WettOverview, setLoading: (p:boolean) => void, token: string, setError: (u:string) => void): void {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison)+"member/bet", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authToken
            },
            method: "POST",
            body: JSON.stringify(data)}
        ).then(r => {
            if(r.ok) {
                return r.json() as Promise<ApiResponse<boolean>>;
            } else {
                const da = r.json() as Promise<ApiResponse<boolean>>;
                da.then(res => setError(res.error));
            }
        })
        .finally(() => setLoading(false));
    }
}