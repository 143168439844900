import React, {useEffect, useMemo, useState} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {useRecoilState} from "recoil";
import {user as userAtom} from "../../../atoms/user";
import DataService from "../../../services/DataService";
import PlayerRow from "../../../component/PlayerRow";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import NewMemberModal from "../../../component/NewMemberModal";
import NewStaffModal from "../../../component/NewStaffModal";
import Form from "react-bootstrap/Form";
import {emptyEnddat, emptyPlayer, emptyTeamOverview} from "../../../util/EmptyObjects";
import Select from "react-select";

const mapToDropDownTeam = (data: Team) => {
    return {label: data.name, value: data.id};
};

const deActivated = (endDate: number|null, isAdmin: boolean): boolean => {
    if(endDate == null || isAdmin) {
        return false;
    }
    const now = Math.floor(Date.now() / 1000);
    if(endDate === 0) {
        return false;
    }
    return endDate < now;
}

export default function TeamBearbeitung() {
    const service = useMemo(() => DataService.getInstance(), []);
    const [user, setUser] = useRecoilState(userAtom);
    const [loading, setLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalStaff, setShowModalStaff] = useState<boolean>(false);
    const [showNewPlayerRow, setShowNewPlayerRow] = useState<boolean>(false);
    const [newMember, setNewMember] = useState<Player[]>([]);
    const [newPlayer, setNewPlayer] = useState<Player>(emptyPlayer);
    const [team, setTeam] = useState<TeamOverview>(emptyTeamOverview);
    const [teams, setTeams] = useState<Team[]>([]);
    const [enddate, setEndDate] = useState<Enddates>(emptyEnddat);

    // @ts-ignore
    const params: { saison: number } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(user.token === "") {
            navigate("/saison/" + params.saison + "/login");
        } else {
            setLoading(true);
            service.team().getAll(params.saison, setTeams, b => b);
            service.dashboard().getEnddate(params.saison, user.token, setUser, setEndDate, b => b);
            service.team().getEditTeam(params.saison, user.token, setUser, d => {
                setTeam(d);
                service.team().getNewMember(params.saison, d.id, setNewMember, setLoading);
            }, b => b);
        }
    }, [user.token, params.saison, navigate, setUser, service]);

    return (
    loading ?
        <div className="spinner-grow" role="status"/>
        :
        <div className="Team">
            {
                user.rolle === "Admin" &&
                <>
                    <Row>
                        <Col>&nbsp;</Col>
                    </Row>
                    <Row>
                        <Col sm={"2"}>
                            <span className={"bold"}>Team:</span>
                        </Col>
                        <Col sm={"10"}>
                            <Select
                                options={teams.map(mapToDropDownTeam)}
                                defaultValue={{
                                    value: team.id,
                                    label: team.name
                                }}
                                onChange={selectedOption => {
                                    if (selectedOption != null) {
                                        setLoading(true);
                                        service.team().getEditTeam(params.saison, user.token, setUser, d => {
                                            setTeam(d);
                                            service.team().getNewMember(params.saison, d.id, setNewMember, setLoading);
                                        }, b => b, selectedOption.value)
                                    }
                                }}
                            ></Select>
                        </Col>
                    </Row>
                </>
            }
            <Row>
                <Col>&nbsp;</Col>
            </Row>
            <Row>
                <Col>
                    <Button variant="primary" size="sm" disabled={team.spieler.length >= 16 || deActivated(enddate.editTeam, user.rolle === "Admin")} onClick={(e) => {
                        setShowModal(true);
                    }}>
                        Neuer Mitspieler&nbsp;<FontAwesomeIcon icon={faPlus}/>
                    </Button>
                    &nbsp;
                    <Button variant="primary" size="sm" disabled={deActivated(enddate.editTeam, user.rolle === "Admin")} onClick={(e) => {
                        setShowModalStaff(true);
                    }}>
                        Neues Teammitglied&nbsp;<FontAwesomeIcon icon={faPlus}/>
                    </Button>
                </Col>
                <Col>
                    <NewMemberModal show={showModal} member={newMember} handleClose={() => setShowModal(false)} handleAdd={p => {
                        setShowModal(false);
                        if(team.spieler.length >= 16) {
                            alert("Das Team besteht schon aus 16 Spielern!");
                        } else if(team.kasse < p.kosten) {
                            alert("Der Spieler ist zu teuer!");
                        } else {
                            setNewPlayer(p);
                            setShowNewPlayerRow(true);
                        }
                    }} />
                    <NewStaffModal
                        show={showModalStaff}
                        heimId={team.id}
                        heimReroll={team.rerolls.kosten}
                        gastId={null}
                        gastReroll={null}
                        handleClose={() => setShowModalStaff(false)}
                        handleAdd={s => {
                            if(team.kasse < s.kosten) {
                                alert("Das Mitglied ist zu teuer!");
                            } else {
                                if(window.confirm("Willst du wirklich "+ s.name+ " kaufen?")) {
                                    service.team().addStaff(params.saison, user.token, setUser, s, setTeam, setLoading);
                                }
                            }
                            setShowModalStaff(false);
                        }} />
                    &nbsp;
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>{team.name}</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <table className="col-sm-12 table table-bordered table-striped table-sm">
                        <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th>Name (Spielernummer)</th>
                            <th>Position</th>
                            <th>BE</th>
                            <th>ST</th>
                            <th>GE</th>
                            <th>WG</th>
                            <th>RW</th>
                            <th>Fertigkeiten</th>
                            <th>Anwerbekosten</th>
                            <th>Verf. SSP</th>
                            <th>VNS</th>
                            <th>HV</th>
                            <th>AD</th>
                            <th>Moment. Wert</th>
                            <th>&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            team.spieler.map((player, index) => <PlayerRow key={player.id} player={player} pos={index + 1}
                                                                           edit={!deActivated(enddate.editTeam, user.rolle === "Admin")}/>)
                        }
                        {
                            showNewPlayerRow &&
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    Name:&nbsp;<Form.Control type="text" defaultValue={newPlayer.name} onChange={event => newPlayer.name = event.target.value} />
                                </td>
                                <td>{newPlayer.position}</td>
                                <td>{newPlayer.stats.be}</td>
                                <td>{newPlayer.stats.st}</td>
                                <td>{newPlayer.stats.ge}</td>
                                <td>{newPlayer.stats.wg}</td>
                                <td>{newPlayer.stats.rw}</td>
                                <td>{newPlayer.stats.skill}</td>
                                <td className="textRight">{newPlayer.kosten.toLocaleString()}</td>
                                <td>&nbsp;</td>
                                <td colSpan={4}>
                                    <Button variant="primary" size="sm" onClick={(e) => {
                                        service.team().addPlayer(params.saison, user.token, setUser, newPlayer, setTeam, setLoading);
                                        setShowNewPlayerRow(false);
                                        setNewPlayer(emptyPlayer);
                                    }}>
                                        <FontAwesomeIcon icon={faPlus}/>
                                    </Button>
                                </td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </Col>
            </Row>
            <Row>
                <Col>
                    <table className="col-sm-12 table table-bordered table-sm">
                        <tbody>
                        <tr>
                            <td>Teamkasse:</td>
                            <td className="textRight">{team.kasse.toLocaleString()}</td>
                        </tr>
                        <tr>
                            <td>Treue Fans:</td>
                            <td className="textRight">{team.fans}</td>
                        </tr>
                        <tr>
                            <td>Touchdowns:</td>
                            <td className="textRight">{team.touchdowns}</td>
                        </tr>
                        <tr>
                            <td>VSA:</td>
                            <td className="textRight">{team.vsa}</td>
                        </tr>
                        <tr>
                            <td>Absolvierte Spiele:</td>
                            <td className="textRight">{team.spiele}</td>
                        </tr>
                        <tr>
                            <td>Ligapunkte:</td>
                            <td className="textRight">{team.punkte}</td>
                        </tr>
                        </tbody>
                    </table>
                </Col>
                <Col>
                    <table className="col-sm-12 table table-bordered table-sm">
                        <tbody>
                        <tr>
                            <td>Wiederholungswürfe:</td>
                            <td className="textCenter">{team.rerolls.anzahl}</td>
                            <td className="textCenter">X</td>
                            <td className="textRight">{team.rerolls.kosten.toLocaleString()}</td>
                            <td className="textRight">{(team.rerolls.anzahl * team.rerolls.kosten).toLocaleString()}</td>
                        </tr>
                        <tr>
                            <td>Trainerassistenten:</td>
                            <td className="textCenter">{team.trainerass}</td>
                            <td className="textCenter">X</td>
                            <td className="textRight">10.000</td>
                            <td className="textRight">{(team.trainerass * 10000).toLocaleString()}</td>
                        </tr>
                        <tr>
                            <td>Cheerleader:</td>
                            <td className="textCenter">{team.cheerleader}</td>
                            <td className="textCenter">X</td>
                            <td className="textRight">10.000</td>
                            <td className="textRight">{(team.cheerleader * 10000).toLocaleString()}</td>
                        </tr>
                        <tr>
                            <td>Sanitäter:</td>
                            <td className="textCenter">{team.sani}</td>
                            <td className="textCenter">X</td>
                            <td className="textRight">50.000</td>
                            <td className="textRight">{(team.sani * 50000).toLocaleString()}</td>
                        </tr>
                        <tr>
                            <td>Teamwert:</td>
                            <td colSpan={4} className="textRight">{team.value.all.toLocaleString()}</td>
                        </tr>
                        <tr>
                            <td>Momentaner Teamwert:</td>
                            <td colSpan={4} className="textRight">{team.value.actual.toLocaleString()}</td>
                        </tr>
                        </tbody>
                    </table>
                </Col>
            </Row>
        </div>
    );
}