import React, {ChangeEvent, useState} from "react";
import {Col, Row} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import { RatingComponent } from 'react-rating-emoji'
import Team from "./Team";

export default function TeamInfo(props: TeamInfoProps) {

    const [fanFaktor, setFanFaktor] = useState<number>(0);
    const [changeFansUp, setChangeFansUp] = useState<number>(0);
    const [changeFansDown, setChangeFansDown] = useState<number>(0);
    const [fans, setFans] = useState<number>(props.data.fans);
    const [bewertung, setBewertung] = useState<number>(props.data.bewertung+3);

    const changeFans = (fans: number, up: number, down: number) : void => {
        setFans(fans)
        // @ts-ignore
        props.setFans(fans);
        setChangeFansDown(prev => prev + down);
        setChangeFansUp(prev => prev + up);
    }

    const changeBewertung = (bewertung: number): void => {
        if(props.edit) {
            setBewertung(bewertung);
            // @ts-ignore
            props.setBewertung(bewertung-3);
        }
    }

    const handleChangeOnFanFaktor: (event: ChangeEvent<HTMLInputElement>) => void = event => {
        let { valueAsNumber, min, max } = event.target;
        let value = Math.max(Number(min), Math.min(Number(max), valueAsNumber));
        setFanFaktor(value);
    };

    return (
        <>
            <Row>
                <Col className={"bold textCenter textLarge"}><Team team={props.data} /></Col>
            </Row>
            <Row>
                <Col className={"bold"}>Nuffle: </Col>
                <Col>
                    {
                        props.edit ?
                            // @ts-ignore
                            <Form.Control type="text" defaultValue={props.data.nuffle} onChange={event => props.setNuffle(event.target.value)} />
                        :
                            props.data.nuffle
                    }
                </Col>
            </Row>
            <Row>
                <Col className={"bold"}>Gesellen: </Col>
                <Col>{props.data.gesellen}</Col>
            </Row>
            <Row>
                <Col className={"bold"}>Anreize: </Col>
                <Col>
                    {
                        props.edit ?
                            // @ts-ignore
                            <Form.Control type="text" defaultValue={props.data.anreize} onChange={event => props.setAnreize(event.target.value)} />
                        :
                            props.data.anreize
                    }
                </Col>
            </Row>
            <Row>
                <Col className={"bold"}>Handkasse: </Col>
                <Col>{props.data.handkasse.toLocaleString()}</Col>
            </Row>
            <Row>
                <Col className={"bold"}>Teamkasse: </Col>
                <Col>{props.data.teamkasse.toLocaleString()}</Col>
            </Row>
            <Row>
                <Col className={"bold"}>momentaner Teamwert: </Col>
                <Col>{props.data.teamwert.toLocaleString()}</Col>
            </Row>
            {
                props.edit &&
                    <Row>
                        <Row>
                            <Col className={"bold"}>Fan Wurf (W3): </Col>
                            <Col>
                                <Form.Control type="number" min={1} max={3} defaultValue={fanFaktor} onChange={handleChangeOnFanFaktor} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className={"bold"}>Fan Faktor(W3 + Treue Fans): </Col>
                            <Col><Form.Control plaintext readOnly value={props.data.fans + fanFaktor} /></Col>
                        </Row>
                    </Row>
            }
            <Row>
                <Col className={"bold"}>Einnahmen: </Col>
                <Col>
                    {
                        props.edit ?
                            // @ts-ignore
                            <Form.Control type="number" defaultValue={props.data.einnahmen} onChange={event => props.setEinnahmen(event.target.value)} />
                            :
                            props.data.einnahmen.toLocaleString()
                    }
                </Col>
            </Row>
            <Row>
                <Col className={"bold"}>Fans: </Col>
                <Col>
                    {
                        props.edit ?
                            <Form.Group as={Row} className="mb-3" controlId="fans">
                                <Col sm="3">
                                    <Button variant="primary"
                                            size="sm"
                                            disabled={changeFansDown > 0}
                                            onClick={(e) => changeFans(fans-1, -1, 1)}>
                                        <FontAwesomeIcon icon={faMinus} />
                                    </Button>
                                </Col>
                                <Col sm="3">
                                    <Form.Control plaintext readOnly value={fans} />
                                </Col>
                                <Col sm="3">
                                    <Button variant="primary"
                                            size="sm"
                                            disabled={changeFansUp > 0}
                                            onClick={(e) => changeFans(fans+1, 1, -1)}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                </Col>
                            </Form.Group>
                            :
                            props.data.fans
                    }
                </Col>
            </Row>
            <Row>
                <Col className={"bold"}>Bewertung: </Col>
                <Col>
                    <RatingComponent rating={bewertung} onClick={changeBewertung}/>
                </Col>
            </Row>
        </>
    );
}