import React from 'react';
import { Routes, Route } from "react-router-dom";
import Team from "./view/saison/Team";
import Overview from "./view/Overview";
import SaisonOverview from "./view/saison/Overview";
import Bericht from "./view/saison/Bericht";
import Layout from "./view/saison/Layout";
import Preview from "./view/Preview";
import Spielplan from "./view/saison/Plan";
import Statistiken from "./view/saison/Statistiken";
import Spielbericht from "./view/saison/Spielbericht";
import Login from "./view/saison/Login";
import BerichtListe from "./view/saison/member/BerichtListe";
import Wetten from "./view/saison/member/Wetten";
import TeamBearbeitung from "./view/saison/member/TeamBearbeitung";
import User from "./view/saison/member/User";
import Admin from "./view/saison/member/Admin";
import EventLog from "./view/saison/member/EventLog";
import Wetter from "./view/saison/Wetter";
import Dashboard from "./view/saison/member/Dashboard";
import TreasuryLog from "./view/saison/member/TreasuryLog";

function App() {
    return (
        <Routes>
            <Route index element={<Overview />} />
            <Route path="saison" element={<Layout />}>
                <Route path=":saison" element={<SaisonOverview />} />
                <Route path=":saison/plan" element={<Spielplan />} />
                <Route path=":saison/bericht" element={<Bericht />} />
                <Route path=":saison/spielbericht/:tag/:heim/:gast" element={<Spielbericht />} />
                <Route path=":saison/statistiken" element={<Statistiken />} />
                <Route path=":saison/team/:id/:spieltag" element={<Team />} />
                <Route path=":saison/wetter/:art" element={<Wetter />} />
                <Route path=":saison/login" element={<Login />} />
                <Route path=":saison/member">
                    <Route path="liste" element={<BerichtListe />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="wetten" element={<Wetten />} />
                    <Route path="team" element={<TeamBearbeitung />} />
                    <Route path="user" element={<User />} />
                    <Route path="admin" element={<Admin />} />
                    <Route path="eventlog" element={<EventLog />} />
                    <Route path="treasury" element={<TreasuryLog />} />
                </Route>
            </Route>
            <Route path="saison-preview" element={<Preview />} />
            <Route path="statistiken" element={<Statistiken />} />
            <Route path="*" element={<Overview />} />
      </Routes>
  );
}

export default App;
