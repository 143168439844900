import React, {useEffect, useMemo, useState} from 'react';
import DataService from "../services/DataService";
import {Link} from "react-router-dom";

export default function SpieltagTab(props: TabProps) {
    const service = useMemo(() => DataService.getInstance(), []);
    const [data, setData] = useState<Number[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        service.team().getTabDaten(props.saison, props.teamId, setData, setLoading);
    }, [service, props.saison, props.teamId]);

    return (
        <ul className="nav nav-tabs">
            {
                loading ?
                    <div className="spinner-grow" role="status" />
                :
                    data.map(d =>
                        <li key={""+d} className="nav-item">
                            {
                                Number(props.spieltag) === d ?
                                    <Link className="nav-link active" aria-current="page" to={props.link + "/" +d}>{d+". Spieltag"}</Link>
                                :
                                    <Link className="nav-link" to={props.link + "/" +d}>{d+". Spieltag"}</Link>
                            }
                        </li>
                    )
            }
        </ul>
    );
}