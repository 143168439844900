import React, {useEffect, useMemo, useState} from 'react';
import {Container} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {useRecoilState} from "recoil";
import {user as userAtom} from "../../../atoms/user";
import Table from "react-bootstrap/Table";
import DataService from "../../../services/DataService";

export default function EventLog() {
    const service = useMemo(() => DataService.getInstance(), []);
    const [user, setUser] = useRecoilState(userAtom);
    const [loading, setLoading] = useState<boolean>(false);
    const [events, setEvents] = useState<AppEvent[]>([]);

    // @ts-ignore
    const params: { saison: number } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(user.token === "") {
            navigate("/saison/" + params.saison + "/login");
        } else {
            setLoading(true);
            service.admin().getEvents(params.saison, user.token, setUser, setEvents, setLoading);
        }
    }, [user.token, params.saison, navigate, setUser, service]);

    return (
        <Container>
            <h1>Events</h1>
            {
                loading ?
                    <div className="spinner-grow" role="status"/>
                    :
                    <Table striped>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>User</th>
                            <th>Zeit</th>
                            <th>Typ</th>
                            <th>Data</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            events.map((data, index) =>
                                <tr key={index}>
                                    <td>{index}</td>
                                    <td>{data.user}</td>
                                    <td>{data.time}</td>
                                    <td>{data.typ}</td>
                                    <td><pre>{data.data}</pre></td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
            }
        </Container>
    );
}