import {atom} from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist();

export const user = atom<User>({
    key: "user",
    default: {token: "", name: "", rolle: ""},
    effects_UNSTABLE: [persistAtom],
    effects: [persistAtom],
});