import React, {useEffect, useMemo, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {useRecoilState} from "recoil";
import {user as userAtom} from "../../atoms/user";
import {useParams} from "react-router-dom";
import DataService from "../../services/DataService";
import Team from "../Team";
import {emptyTeamOverview} from "../../util/EmptyObjects";


export default function TeamInfo() {
    const service = useMemo(() => DataService.getInstance(), []);
    const [loading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useRecoilState(userAtom);
    const [team, setTeam] = useState<TeamOverview>(emptyTeamOverview);

    // @ts-ignore
    const params: { saison: number } = useParams();

    useEffect(() => {
        if(user.token === "") {
            setLoading(true);
        } else {
            setLoading(true);
            service.dashboard().getTeamtData(params.saison, user.token, setUser, setTeam, setLoading);
        }
    }, [service, user.token, params.saison, setUser]);

    return (
        <Container className={"greyBg"}>
            {
                loading ?
                    <div className="spinner-grow" role="status"/>
                :
                    <p>
                        <Row>
                            <Col>
                                <Team team={team} />
                            </Col>
                        </Row>
                        <Row>
                            <Col><strong>Team Info</strong></Col>
                        </Row>
                        <Row>
                            <Col>Teamwert: </Col>
                            <Col>{team.value.all}</Col>
                            <Col>Momentaner Teamwert: </Col>
                            <Col>{team.value.actual}</Col>
                        </Row>
                        <Row>
                            <Col><strong>Spieler Info</strong></Col>
                        </Row>
                        <Row>
                            <Col>Anzahl aktiver Spieler:</Col>
                            <Col>{team.spieler.filter(p => !p.vhs).length}</Col>
                        </Row>
                        <Row>
                            <Col>Folgende Spieler verpassen das nächste Spiel:</Col>
                            <Col>
                                {
                                    team.spieler.filter(p => p.vhs).length === 0 ?
                                        <span>Keiner </span>
                                    :
                                        <ul>
                                        {
                                            team.spieler.filter(p => p.vhs).map(p => <li>{p.name}</li>)
                                        }
                                        </ul>
                                }
                            </Col>
                        </Row>
                    </p>
            }
        </Container>);
}